var render = function render(){var _vm=this,_c=_vm._self._c;return _c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
    content: _vm.$options.filters.markdown(_vm.text),
    placement: 'top-center',
    offset: 5,
    delay: {
      show: 100,
      hide: 200,
    },
  }),expression:"{\n    content: $options.filters.markdown(text),\n    placement: 'top-center',\n    offset: 5,\n    delay: {\n      show: 100,\n      hide: 200,\n    },\n  }"}],staticClass:"icon",attrs:{"icon":['fa', 'info-circle']}})
}
var staticRenderFns = []

export { render, staticRenderFns }