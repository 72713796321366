var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("View participant")]),_c('button',{staticClass:"delete",attrs:{"aria-label":"close"},on:{"click":function($event){return _vm.$parent.close()}}})]),_c('section',{staticClass:"modal-card-body"},[_c('table',{staticClass:"table is-narrow is-fullwidth"},[_c('tr',[_vm._m(0),_c('td',[_vm._v(_vm._s(_vm.participant.first_name))])]),_c('tr',[_vm._m(1),_c('td',[_vm._v(_vm._s(_vm.participant.last_name))])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.participant.body_name))])]),_c('tr',[_vm._m(3),_c('td',[_vm._v(_vm._s(_vm.participant.notification_email))])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(_vm._s(_vm._f("date")(_vm.participant.created_at)))])]),_vm._l((_vm.event.questions),function(field,index){return _c('tr',{key:index},[_c('td',[_c('b',[_vm._v(_vm._s(field.description))])]),_c('td',[_vm._v(_vm._s(_vm._f("beautify")(_vm.participant.answers[index])))])])}),_c('tr',[_vm._m(5),_c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.participant.board_comment),expression:"participant.board_comment"}]},[_vm._v(_vm._s(_vm.participant.board_comment))]),_c('td',{directives:[{name:"show",rawName:"v-show",value:(!_vm.participant.board_comment),expression:"!participant.board_comment"}]},[_vm._v("-")])])],2)]),_c('footer',{staticClass:"modal-card-foot"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("First Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Last Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Body")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Application date")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Board comment")])])
}]

export { render, staticRenderFns }